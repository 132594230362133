// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.input-group {
	position: relative;
	display: flex;
	align-items: stretch;
	width: 100%;

	.form-control {
		// Ensure that the input is always above the *appended* addon button for
		// proper border colors.
		position: relative;
		z-index: 2;
		flex: 1 1 auto;
		// Add width 1% and flex-basis auto to ensure that button will not wrap out
		// the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
		width: 1%;
		margin-bottom: 0;

		// Bring the "active" form control to the front
		@include hover-focus-active {
			z-index: 3;
		}
	}
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: flex;
	align-items: center;
	&:not(:first-child):not(:last-child) {
		@include border-radius(0);
	}
}

.input-group-addon,
.input-group-btn {
	white-space: nowrap;
}

// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	@extend .form-control-lg;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	@extend .form-control-sm;
}

//
// Text input groups
//

.input-group-addon {
	padding: $input-btn-padding-y $input-btn-padding-x;
	margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
	font-size: $font-size-base; // Match inputs
	font-weight: $font-weight-normal;
	line-height: $input-btn-line-height;
	color: $input-group-addon-color;
	text-align: center;
	background-color: $input-group-addon-bg;
	border: $input-btn-border-width solid $input-group-addon-border-color;
	@include border-radius($input-border-radius);

	// Sizing
	&.form-control-sm {
		padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
		font-size: $font-size-sm;
		@include border-radius($input-border-radius-sm);
	}

	&.form-control-lg {
		padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
		font-size: $font-size-lg;
		@include border-radius($input-border-radius-lg);
	}

	// Nuke default margins from checkboxes and radios to vertically center within.
	input[type="radio"],
	input[type="checkbox"] {
		margin-top: 0;
	}
}

//
// Reset rounded corners
//

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
	@include border-right-radius(0);
}

.input-group-addon:not(:last-child) {
	border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
	@include border-left-radius(0);
}

.form-control + .input-group-addon:not(:first-child) {
	border-left: 0;
}

//
// Button input groups
//

.input-group-btn {
	position: relative;
	align-items: stretch;
	// Jankily prevent input button groups from wrapping with `white-space` and
	// `font-size` in combination with `inline-block` on buttons.
	font-size: 0;
	white-space: nowrap;

	// Negative margin for spacing, position for bringing hovered/focused/actived
	// element above the siblings.
	> .btn {
		position: relative;

		+ .btn {
			margin-left: (-$input-btn-border-width);
		}

		// Bring the "active" button to the front
		@include hover-focus-active {
			z-index: 3;
		}
	}

	&:first-child > .btn + .btn {
		margin-left: 0;
	}

	// Negative margin to only have a single, shared border between the two
	&:not(:last-child) {
		> .btn,
		> .btn-group {
			margin-right: (-$input-btn-border-width);
		}
	}
	&:not(:first-child) {
		> .btn,
		> .btn-group {
			z-index: 2;
			// remove nagative margin ($input-btn-border-width) to solve overlapping issue with button.
			margin-left: 0;

			// When input is first, overlap the right side of it with the button(-group)
			&:first-child {
				margin-left: (-$input-btn-border-width);
			}

			// Because specificity
			@include hover-focus-active {
				z-index: 3;
			}
		}
	}
}
