// Base class
.tooltip {
	position: absolute;
	z-index: $zindex-tooltip;
	display: block;
	margin: $tooltip-margin;
	// Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
	// So reset our font and text properties to avoid inheriting weird values.
	@include reset-text();
	font-size: $font-size-sm;
	// Allow breaking very long words so they don't overflow the tooltip's bounds
	word-wrap: break-word;
	opacity: 0;

	&.show {
		opacity: $tooltip-opacity;
	}

	.arrow {
		position: absolute;
		display: block;
		width: $tooltip-arrow-width;
		height: $tooltip-arrow-height;
	}

	.arrow::before {
		position: absolute;
		border-color: transparent;
		border-style: solid;
	}

	&.bs-tooltip-top {
		padding: $tooltip-arrow-width 0;
		.arrow {
			bottom: 0;
		}

		.arrow::before {
			margin-left: -($tooltip-arrow-width - 2);
			content: "";
			border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
			border-top-color: $tooltip-arrow-color;
		}
	}
	&.bs-tooltip-right {
		padding: 0 $tooltip-arrow-width;
		.arrow {
			left: 0;
		}

		.arrow::before {
			margin-top: -($tooltip-arrow-width - 2);
			content: "";
			border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
			border-right-color: $tooltip-arrow-color;
		}
	}
	&.bs-tooltip-bottom {
		padding: $tooltip-arrow-width 0;
		.arrow {
			top: 0;
		}

		.arrow::before {
			margin-left: -($tooltip-arrow-width - 2);
			content: "";
			border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
			border-bottom-color: $tooltip-arrow-color;
		}
	}
	&.bs-tooltip-left {
		padding: 0 $tooltip-arrow-width;
		.arrow {
			right: 0;
		}

		.arrow::before {
			right: 0;
			margin-top: -($tooltip-arrow-width - 2);
			content: "";
			border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
			border-left-color: $tooltip-arrow-color;
		}
	}
	&.bs-tooltip-auto {
		&[x-placement^="top"] {
			@extend .bs-tooltip-top;
		}
		&[x-placement^="right"] {
			@extend .bs-tooltip-right;
		}
		&[x-placement^="bottom"] {
			@extend .bs-tooltip-bottom;
		}
		&[x-placement^="left"] {
			@extend .bs-tooltip-left;
		}
	}
}

// Wrapper for the tooltip content
.tooltip-inner {
	max-width: $tooltip-max-width;
	padding: $tooltip-padding-y $tooltip-padding-x;
	color: $tooltip-color;
	text-align: center;
	background-color: $tooltip-bg;
	@include border-radius($border-radius);
}
