// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
	position: relative;
}

.dropdown-toggle {
	// Generate the caret automatically
	@include caret;
}

// The dropdown menu
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: $zindex-dropdown;
	display: none; // none by default, but block on "open" of the menu
	float: left;
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y 0;
	margin: $dropdown-spacer 0 0; // override default ul
	font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
	color: $body-color;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	list-style: none;
	background-color: $dropdown-bg;
	background-clip: padding-box;
	border: $dropdown-border-width solid $dropdown-border-color;
	@include border-radius($border-radius);
	@include box-shadow($dropdown-box-shadow);
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
	.dropdown-menu {
		margin-top: 0;
		margin-bottom: $dropdown-spacer;
	}

	.dropdown-toggle {
		@include caret(up);
	}
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
	@include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
	display: block;
	width: 100%; // For `<button>`s
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	clear: both;
	font-weight: $font-weight-normal;
	color: $dropdown-link-color;
	text-align: inherit; // For `<button>`s
	white-space: nowrap; // prevent links from randomly breaking onto new lines
	background: none; // For `<button>`s
	border: 0; // For `<button>`s

	@include hover-focus {
		color: $dropdown-link-hover-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-hover-bg);
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-active-bg);
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		background-color: transparent;
		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}
}

.dropdown-menu.show {
	display: block;
}

// Dropdown section headers
.dropdown-header {
	display: block;
	padding: $dropdown-padding-y $dropdown-item-padding-x;
	margin-bottom: 0; // for use with heading elements
	font-size: $font-size-sm;
	color: $dropdown-header-color;
	white-space: nowrap; // as with > li > a
}
