// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
	display: flex;
	flex-direction: column;

	// No need to set list-style: none; since .list-group-item is block level
	padding-left: 0; // reset padding because ul and ol
	margin-bottom: 0;
}

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive
// list items. Includes an extra `.active` modifier class for selected items.

.list-group-item-action {
	width: 100%; // For `<button>`s (anchors become 100% by default though)
	color: $list-group-action-color;
	text-align: inherit; // For `<button>`s (anchors inherit)

	// Hover state
	@include hover-focus {
		color: $list-group-action-hover-color;
		text-decoration: none;
		background-color: $list-group-hover-bg;
	}

	&:active {
		color: $list-group-action-active-color;
		background-color: $list-group-action-active-bg;
	}
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
	position: relative;
	display: block;
	padding: $list-group-item-padding-y $list-group-item-padding-x;
	// Place the border on the list items and negative margin up for better styling
	margin-bottom: -$list-group-border-width;
	background-color: $list-group-bg;
	border: $list-group-border-width solid $list-group-border-color;

	&:first-child {
		@include border-top-radius($list-group-border-radius);
	}

	&:last-child {
		margin-bottom: 0;
		@include border-bottom-radius($list-group-border-radius);
	}

	@include hover-focus {
		text-decoration: none;
	}

	&.disabled,
	&:disabled {
		color: $list-group-disabled-color;
		background-color: $list-group-disabled-bg;
	}

	// Include both here for `<a>`s and `<button>`s
	&.active {
		z-index: 2; // Place active items above their siblings for proper border styling
		color: $list-group-active-color;
		background-color: $list-group-active-bg;
		border-color: $list-group-active-border-color;
	}
}

// Flush list items
//
// Remove borders and border-radius to keep list group items edge-to-edge. Most
// useful within other components (e.g., cards).

.list-group-flush {
	.list-group-item {
		border-right: 0;
		border-left: 0;
		border-radius: 0;
	}

	&:first-child {
		.list-group-item:first-child {
			border-top: 0;
		}
	}

	&:last-child {
		.list-group-item:last-child {
			border-bottom: 0;
		}
	}
}

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $color, $value in $theme-colors {
	@include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}
